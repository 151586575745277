export default {
  social: {
    github: "//github.com/oorestisime/oioannou/",
  },
  menu: [
    {
      label: "Home",
      path: "/",
    },
    {
      label: "Archive",
      path: "/blog",
    },
  ],
}
